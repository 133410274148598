export default {
    computed: {
        parent_locale: {
            get(){
                return this.$root.locale
            },
            set(val){
                this.$root.locale = val
            }
        }
    },
    methods: {

        t(str, opts={}){
            this.$root.translations //hack to make lazy load reactive
            return I18n.t(str,opts)
        },
        l(str, opts={}){
            this.$root.translations //hack to make lazy load reactive
            return I18n.toTime(opts.format,str)
        },

    },

}