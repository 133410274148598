<template></template>
<script>
    import helpers from 'code/base_mixins'
    export default {
        name: "resend-activate",
        mixins: [helpers],
        created (){
            this.$http.post('/api/resend_activate', {
                token: this.$route.params.token
            }).then((res)=> {
                this.$store.state.flash_notice = I18n.t('app.resend_activate.email_sent')
                this.$router.push({path: "/login"})
            })
        }
    }
</script>