<template>
<div class="row">
  <div class="columns large-13 large-centered">
    <header><div class="title">Log in to your account</div></header>

    <div class="content auth-content">
      <div class="error" v-if="form_error">{{form_error}}</div>
      <div class="row">
        <div class="form-col large-10">
          <label for="user_email">
            Email
            <span class="required">*</span>
            <small>Type your account email</small>
          </label>
        </div>

        <div class="form-col large-14">
          <div class="field">
            <Field
                rules="required|email"
                v-slot="{field, errors}"
                name="email">
              <input
                type="text"
                id="user_email"
                v-bind="field"
                placeholder="Email"
                name="email"
                v-model="email"
                :class="errors[0] && 'error'"
              />
            </Field>
            <div class="forgot">
              <router-link to="/forgot-email">Reset account email</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-col large-10">
          <label for="password">
            Password
            <span class="required">*</span>
            <small>Type your account password</small>
          </label>
        </div>

        <div class="form-col large-14">
          <div class="field">
            <Field
                rules="required|min:8"
                v-slot="{field, errors}"
                name="password">
              <input
                type="password"
                id="password"
                v-bind="field"
                placeholder="Password (min. 8 characters)"
                name="password"
                v-model="password"
                :class="errors[0] && 'error'"
              />
            </Field>
            <div class="forgot">
              <router-link to="/forgot">Forgot password?</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row separator-row"></div>
      <div class="row button-row">
        <div class="form-col large-24 reverse-button-order">
          <div class="buttons left small">
            <a v-if="$route.query.redirect_uri" :href="$route.query.redirect_uri" class="button secondary small">Cancel</a>
          </div>
          <div class="buttons right small">
            <button class="button" @click.prevent="onLogin">Log in</button>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
</template>
<script>
  import {request} from "./api.js"

  export default{
    data(){
      return {
        email:    '',
        password: '',
        form_error: null,
      }
    },

    methods:{
      onLogin(e){
        // check if logged into
        request({
          method: 'post',
          path: "auth/signin",
          data: {user: {email: this.email, password: this.password}, include: ["accesses"]},
        }).then( (res)=>{
          if(res.object){
            this.$emit('setUser', res)
          }else{
            if(res.errors.hasOwnProperty('login_failure')){
              this.form_error = "Login credentials are invalid"
            }else{
              this.form_error = "Sorry, there was an error"
              console.log(res)
              alert("Unknown error happened")
            }
          }
        }).catch( (res)=> console.log(res))
      }
    },
  }
</script>
<style scoped lang="scss">
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
  }
</style>
