<template>
  <div>
    <div class="row">
      <div class="columns large-13 large-centered">
        <div class="content-block">
          <header>
            <div class="title">{{t('app.reset_email.title')}}</div>
          </header>
          <div class="content auth-content">
            <Form ref="form"
                  tag="form"
                  data-auth=""
                  id="new_user"
                  class="new_user"
                  @keydown.enter.prevent="onSubmit">
              <div class="row">
                <div class="form-col large-10">
                  <label for="token">
                    {{t('app.reset_email.code')}}
                  </label>
                </div>

                <div class="form-col large-14">
                  <div class="field">
                    <Field
                        rules="required|min:6"
                        v-slot="{field, errors}"
                        :name="t('app.reset_email.code_val').toLowerCase()">
                      <input type="text"
                             id="token"
                             name="token"
                             v-bind="field"
                             v-model="token"
                             :placeholder="t('app.reset_email.code_placeholder')"
                             :class="errors[0] && 'error'">
                    </Field>
                    <div class="forgot">
                      <a @click.prevent="forgotEmail">{{t('app.reset_email.resend_code')}}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-col large-10">
                  <label for="email">
                    {{t('app.reset_email.new_email')}}
                  </label>
                </div>
                <div class="form-col large-14">
                  <div class="field">
                    <Field
                        mode="lazy"
                        rules="required|email"
                        v-slot="{field, errors}"
                        :name="t('app.reset_email.email').toLowerCase()">
                      <input type="email"
                             id="email"
                             name="email"
                             v-bind="field"
                             v-model="email"
                             :placeholder="t('app.reset_email.email_placeholder')"
                             :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                      <span v-if="email_error" class="error">{{email_error}}</span>
                    </Field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-col large-10">
                  <label for="email_confirmation">
                    {{t('app.reset_email.retype_email')}}
                  </label>
                </div>
                <div class="form-col large-14">
                  <div class="field">
                    <Field
                        mode="lazy"
                        v-slot="{field, errors}"
                        rules="required|email|confirmed:@email_confirmed"
                        :name="t('app.signup.email_confirmation').toLowerCase()"
                    >
                      <input type="email"
                             id="email_confirmation"
                             name="email_confirmation"
                             v-bind="field"
                             v-model="email_confirmation"
                             :placeholder="t('app.reset_email.retype_email_placeholder')"
                             :class="emailMatchError && 'error'">
                      <span v-if="emailMatchError" class="error">{{errors[0]}}</span>
                    </Field>
                  </div>
                </div>
              </div>
              <div class="row separator-row"></div>
              <div class="row button-row">
                <div class="form-col large-24 reverse-button-order">
                  <div class="buttons left small">
                    <a :href="$fid.weburl" class="button secondary small">{{t('app.general.back_home')}}</a>
                  </div>

                  <div class="buttons right small">
                    <router-link to="/login" class="button secondary small">{{t('app.general.cancel')}}</router-link>

                    <button class="button" @click.prevent="onSubmit">{{t('app.general.reset')}}</button>
                  </div>
                </div>
              </div>
            </Form>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import helpers from 'code/base_mixins'

  export default {
    name: "reset-email",
    mixins: [helpers],
    data() {
      return {
        token: null,
        email: null,
        email_confirmation: null,
        email_error: null,
        email_exists: false
      }
    },
    beforeMount (){
      this.$store.commit('clearFlash')
    },
    computed: {
      emailMatchError() {
        if (this.email && this.email_confirmation) {
          return this.email !== this.email_confirmation
            ? true
            : false;
        }
        return null;
      }
    },
    methods: {
      onSubmit() {
        this.$store.commit('clearFlash')
        let that = this

        this.$refs.form.validate().then((result) => {
          if (!result) return;

          that.$http.post('/api/reset_email', {
            user: {email: that.email, email_confirmation: that.email_confirmation, token: that.token}
          }).then(that.successCallback, that.errorCallback);
        })
      },
      successCallback(res) {
        let json = res.data

        if (json.completed) {
          this.$store.commit('redirectAfterLogin', json)
        } else {
          if (json.errors.email) {
            this.$store.state.flash_alert = I18n.t('app.reset_email.email_confirmed') +" <a class='auth_alert_link' href='/login'>" + I18n.t('app.reset_email.click_here')+"</a>"
          } else {
            this.$store.state.flash_alert = I18n.t('app.reset_email.error')
          }

        }

      },
      errorCallback() {
        alert("Unknown error happened")
      },
      forgotEmail() {
        this.$store.commit('clearFlash')

        let that = this
        let phone = window.sessionStorage.getItem('mobile_phone')

        if (!phone) return;

        that.$http.post('/api/forgot_email', {
          user: {mobile_phone: phone}, resend: 1
        }).then(that.forgotEmailSuccessCallback, that.errorCallback);
      },
      forgotEmailSuccessCallback(res) {
        let json = res.data

        if (json.completed) {
          this.$store.state.flash_notice = I18n.t('app.reset_email.text_message_sent')
          this.$router.push({path: "/reset-email"})
        } else {
          this.$store.state.flash_alert =  I18n.t('app.reset_email.no_account')
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }

</style>