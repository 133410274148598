<template>
  <div class="row">
    <div class="columns large-13 large-centered">
      <header>
        <div class="title">Logged in</div>
      </header>

      <div class="content auth-content">
        <div class="error" v-if="form_error">{{form_error}}</div>

        <div class="row confirm-user">
          <div class="initials">{{initials(userName)}}</div>
          <div class="details">
            <div class="name">{{userName}}</div>
            <div class="email">{{userEmail}}</div>
          </div>
        </div>

        <div class="row">
          <div class="form-col large-10">
            <label for="user_password">
              Password
              <span class="required">*</span>
              <small>Type your account password</small>
            </label>
          </div>

          <div class="form-col large-14">
            <div class="field">
              <Field
                  rules="required|min:8"
                  v-slot="{field, errors}"
                  name="email">
                <input
                  type="password"
                  id="user_password"
                  placeholder="Password (min. 8 characters)"
                  v-bind="field"
                  name="password"
                  v-model="password"
                  :class="errors[0] && 'error'"
                />
              </Field>
              <div class="forgot">
                <router-link to="/forgot">Forgot password?</router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row separator-row"></div>
        <div class="row button-row">
          <div class="form-col large-24 reverse-button-order">
            <div class="buttons left small">
              <a @click="$emit('delUser')" class="button secondary small">Not you?</a>
            </div>
            <div class="buttons right small">
              <button class="button" @click.prevent="onLogin">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {request} from "./api.js"

  export default {
    props: {
      userEmail: String,
      userName: String,
    },
    data() {
      return {
        password: '',
        form_error: null,
      }
    },

    methods: {
      onLogin(e) {
        request({
          method: 'post',
          path: "auth/signin",
          data: {user: {email: this.userEmail, password: this.password}, include: ["accesses"]}
        }).then((res) => {
          if (res.object) {
            this.$emit('setUser', res)
          } else {
            if (res.errors.hasOwnProperty('login_failure')) {
              console.log(res)
              this.form_error = "Login credentials are invalid"
            } else {
              this.form_error = "Sorry, there was an error"
              console.log(res)
              alert("Unknown error happened")
            }
          }
        }).catch((res) => console.log(res))
      },
      initials(name) {
        let names = name.split(' ')
        let initials = names[0].substring(0, 1).toUpperCase()
        if (names.length > 1)
          initials += names[names.length - 1].substring(0, 1).toUpperCase()
        return initials
      },
    },
  }
</script>

<style scoped lang="scss">
.confirm-user {
  border: 1px solid #D9D9D9;
  background: #FAFAFA;
  border-radius: 4px;
  padding: 9px 10px;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  .initials {
    width: 56px;
    height: 56px;
    background: #F0F0F0;
    border-radius: 50%;
    margin-right: 8px;
    color: #646464;
    line-height: 1;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding-top: 18px;
  }
  .details {
    width: auto;
    height: fit-content;
    .name {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .email{
      font-size: 14px;
    }
  }
}
.error{
  background-color: #fae3df;
  color: #b61e11;
  border-radius: 4px;
  border: none;
  font-size: 1.3em;
  font-weight: 500;
  text-align: left;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.25rem;
}
</style>
