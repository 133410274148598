function request(pars){
  let ops    = {}
  ops.method = 'POST'
  ops.headers= {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X-CSRF-Token': window.csrf(),
  }
  if(pars){
    let to_send = {}
    for(let [k,v] of Object.entries(pars)) to_send[k]  = v
    to_send['client_id']= new URL(window.location).searchParams.get('client_id')
    ops.body    = JSON.stringify(to_send)
  }
  return fetch('/api/oauth_request', ops).then(res=>res.json())
}

export {
  request
}