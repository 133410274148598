<template>
  <div class="row">
    <div class="columns large-centered" :class="largeClass">
      <div class="content-block" v-bind:class="{'success-content': form_sent}">
        <header>
          <div class="title">{{t('app.forgot_pass.title')}}</div>
        </header>
        <div class="content auth-content">
          <Form ref="form"
                tag="form"
                data-auth=""
                id="new_user"
                class="new_user"
                @keydown.enter.prevent="onSubmit"
                v-if="!form_sent">
            <div class="row">
              <div class="form-col large-8">
                <label>
                  {{t('app.forgot_pass.type_email')}}
                  <span class="required">*</span>
                  <small>{{t('app.forgot_pass.reset_pass')}}</small>
                </label>
              </div>

              <div class="form-col large-16">
                <div class="field">
                  <Field
                      mode="lazy"
                      rules="required|email"
                      v-slot="{field, errors}"
                      :name="t('app.forgot_pass.email').toLowerCase()">
                    <input type="email"
                           id="email"
                           name="email"
                           v-bind="field"
                           v-model="email"
                           :placeholder="t('app.reset_email.email_placeholder')"
                           :class="errors[0] && 'error'">
                    <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                  </Field>
                </div>
              </div>

              <div class="form-col large-16">
                <div class="field">
                  <Field
                      mode="lazy"
                      v-slot="{field, errors}"
                      rules="required|email|confirmed:@email"
                      :name="t('app.signup.email_confirmation').toLowerCase()">
                    <input type="email"
                           id="email_confirmation"
                           name="email_confirmation"
                           v-bind="field"
                           v-model="email_confirmation"
                           :placeholder="t('app.reset_pass.email_confirmation')"
                           :class="errors[0] && 'error'">
                    <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                  </Field>
                </div>
              </div>
            </div>

            <div class="row separator-row"></div>
            <div class="row button-row">
              <div class="form-col large-24 reverse-button-order">
                <div class="buttons left small">
                  <a :href="$fid.weburl" class="button secondary small">{{t('app.general.back_home')}}</a>
                </div>

                <div class="buttons right small">
                  <router-link to="/login" class="button secondary small">{{t('app.general.cancel')}}</router-link>

                  <button class="button" @click.prevent="onSubmit">{{t('app.general.reset')}}</button>
                </div>
              </div>
            </div>
          </Form>
          <div v-if="form_sent">
            <div class="sent-form">
              <p>{{t('app.forgot_pass.instrucitons_1')}}</p>
              <p>{{t('app.forgot_pass.instrucitons_2')}}
                <a :href="`mailto:${this.mail_to}?subject=Password Reset Request`">{{t('app.forgot_pass.instrucitons_3')}}</a>
              </p>
              <div class="buttons clearfix">
                <router-link to="/login" class="button primary centre">{{t('app.general.login')}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import helpers from 'code/base_mixins'

export default {
  name: "forgot-pass",
  mixins: [helpers],
  data() {
    return {
      form_sent: false,
      email: null,
      email_confirmation: null,
      mail_to: process.env.RAILS_ENV === "production" ? "support@arbitersports.com" : "noreply@arbitersports.com"
    }
  },
  beforeMount (){
    this.$store.commit('clearFlash')
  },

  methods: {
    onSubmit() {
      this.$store.commit('clearFlash')
      let that = this

      this.$refs.form.validate().then((result) => {
        if (!result.valid) return;

        that.$http.post('/api/forgot_pass', {
          user: {email: that.email}
        }).then(that.successCallback, that.errorCallback);
      })
    },
    successCallback(res) {
      let json = res.data

      if (json.completed) {
        this.form_sent = true
        this.$store.state.flash_notice = I18n.t('app.forgot_pass.email_sent', {email: this.email})
      } else {
        if (json.errors.activate_resend){
          this.$store.state.flash_alert = I18n.t('app.forgot_pass.alert_1') +
              "<a class='auth_alert_link' href='/resend-activate/" + json.errors.activate_resend + "'>" + I18n.t('app.forgot_pass.alert_2') + "</a>" +
              I18n.t('app.forgot_pass.alert_3')
        }else this.$store.state.flash_alert = json.errors

      }

    },
    errorCallback() {
      alert("Unknown error happened")
    }
  },
  computed:{
    largeClass(){
      if (this.form_sent) {
        return 'large-16'
      }else{
        return 'large-13'

      }
    }
  }
}
</script>
<style scoped lang="scss">
.error{
  background-color: #fae3df;
  color: #b61e11;
  border-radius: 4px;
  border: none;
  font-size: 1.3em;
  font-weight: 500;
  text-align: left;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.25rem;
}

</style>
