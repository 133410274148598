<template>
  <div class="row">
    <div class="columns large-13 large-centered">
      <header>
        <div class="title">Authorization</div>
      </header>

      <div class="content auth-content">
        <div class="error" v-if="auth.errors">
          Sorry there was an error.
          <ul>
            <li v-for="(errs,k) in auth.errors">{{errs.join(', ')}}</li>
          </ul>
        </div>
      </div>
      <div class="content auth-content">

        <p class="note">
          You must have the Connect to other software (API) privilege to make this connection.
        </p>
        <div class="grid">
          <div class="grid-2">
            <form>
              <div class="field">
                <label>Organization</label>
                <div class="custom-select" :class="org_select_opened && 'open'">
                  <div class="caret"/>
                  <div class="selected placeholder" @click="closeSelects('org_select_opened')">
                    {{active_org.id ? active_org.name : 'Select organization' }}
                  </div>
                  <ul>
                    <li>org 1</li>
                    <li>org 2</li>
                    <li v-for="o in orgs" @click="closeSelects(); selectOrg($event, o)">
                      {{o.id}} - {{o.name}}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="field" v-if="programs.length >0">
                <label>Program</label>
                <div class="custom-select combobox" :class="prog_select_opened && 'open'" data-select="custom">
                  <div class="caret"/>
                  <div class="selected placeholder" @click="closeSelects('prog_select_opened')">Select programs</div>
                  <ul>
                    <li v-for="pr in programs">
                      <label :for="'program_' + pr.id">
                        <div class="custom-checkbox">
                          <input type="checkbox" :id="'program_' + pr.id" :value="pr.id" v-model="selected_programs"/>
                          <div class="check"/>
                          <span>{{pr.name}}</span>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
          <div class="grid-2">
            <div class="permissions">
              This application
              <b> Arbiter Registration </b>
              wants the following permissions:

              <ul>
                <li v-for="(value, key) in auth.object.scopes">{{value||key}}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row separator-row"></div>
        <div class="row button-row">
          <div class="form-col large-24 reverse-button-order">
            <div class="buttons left small">
              <button class="button secondary" @click="onCancel">Cancel</button>
            </div>
            <div class="buttons right small">
              <button class="button primary" :disabled="selected_programs.length===0" @click.prevent="onConfirm">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {request} from "./api.js"

  export default {
    data() {
      return {
        auth: {object: {}},
        orgs: [],
        active_org: {},
        programs: [],
        selected_programs: [],
        org_select_opened: false,
        prog_select_opened: false,
      }
    },
    created() {
      this.$parent.$parent.hide_menu = true
      this.checkOauthAccess().then(this.loadOrgs)
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('click', (e) => {
          if (!e.target.closest('.custom-select')) this.closeSelects();
        }, false)
      })
    },
    methods: {
      closeSelects(open_select) {
        let old_val
        if (open_select) old_val = this[open_select]

        this.org_select_opened = false
        this.prog_select_opened = false

        if (open_select) this[open_select] = !old_val
      },
      checkOauthAccess() {
        return request({
          method: 'post',
          path: "oauth_auth" + window.location.search,
          auth_token: this.$parent.token,
        }).then((res) => {
          this.auth = res
        }).catch((res) => console.log(res))
      },
      loadOrgs() {
        request({
          method: 'get',
          path: "organizations",
          data: {filter: {for_right: 'connect_to_api'}, order: "name", per: 100},
          auth_token: this.$parent.token,
        }).then((res) => {
          if (res.object) {
            this.orgs = res.object
            for (let o of this.orgs) {
              if (this.auth.object.programs && o.id.toString() === Object.keys(this.auth.object.programs)[0]) {
                this.active_org = o
                this.selectOrg(null, o)
                break
              }
            }
            // this.loadOtherOrgs()
          } else {
            alert(res.errors.toSource())
          }
        }).catch((res) => console.log(res))
      },
      selectOrg(e, org) {
        this.active_org = org
        this.programs = []
        this.selected_programs = []

        request({
          method: 'get',
          path: "programs",
          data: {organization_id: this.active_org.id},
          auth_token: this.$parent.token,
        }).then((res) => {

          if (res.object) {
            this.programs = res.object
            for (let o of res.object) {
              for (let old_id of ((this.auth.object.programs || {})[org.id.toString()] || [])) {
                if (o.id.toString() === old_id) this.selected_programs.push(o.id);
              }
            }
          } else {
            alert(res.errors.toSource())
          }
        }).catch((res) => console.log(res))
      },
      onConfirm(e) {
        let programs = {}
        programs[this.active_org.id] = this.selected_programs
        let data = {allow: true, organization_id: this.active_org.id, programs: programs}
        for (let [k, v] of Object.entries(this.auth.object.params)) data[k] = v;

        request({
          method: 'post',
          path: "oauth_auth/allow",
          auth_token: this.$parent.token,
          data: data,
        }).then((res) => window.location.replace(res.object.redirect_uri)
        ).catch((res) => console.log(res))
      },
      loadOtherOrgs() {
        let o_ids = []
        console.log(this.$root.current_user.accesses)
        for (let access of this.$root.current_user.accesses) {
          // add here assign_users check
          if (access.acl && access.acl.connect_to_api) {
            o_ids.push(access.organization_id)
          }
        }


        request({
          method: 'get',
          path: "organizations",
          data: {ids: o_ids},
          auth_token: this.$parent.token,
        }).then((res) => {
          if (res.object) {
            for (let o of res.object)
              if (this.orgs.indexOf(o) === -1) this.orgs.push(o);
          }
        })
      },
      onCancel(){ return window.location = this.$route.query.redirect_uri },
    }
  }
</script>

<style scoped lang="scss">
  .permissions {
    border: 1px solid #D9D9D9;
    background: #FAFAFA;
    border-radius: 4px;
    padding: 9px 10px;
    font-size: 12px;
    @media (min-width: 768px) {
      margin-top: 5px;
    }
    ul {
      list-style: disc;
      li {
        margin-left: 10px;
      }
    }
  }
  .content {
    p {
      padding: 0 10px;
    }
  }
  .grid {
    margin: 0 -10px;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
    }
    .grid-2 {
      flex: 0 0 calc(50% - 10px);
    }
  }
  .field {
    label {
      font-size: 12px;
    }
  }
  .custom-select {
    position: relative;
    font-size: 13px;
    margin-bottom: 10px;
    cursor: pointer;
    .selected.placeholder {
      border: 1px solid #D9D9D9;
      padding: 5px 10px;
      border-radius: 4px;
    }
    ul {
      overflow: hidden;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      background: #fcfcfc;
      position: absolute;
      width: 100%;
      top: calc(100% + 5px);
      display: none;
      li {
        padding: 5px 10px;
        transition: 0.1s background-color;
        cursor: pointer;
        &:hover {
          background-color: white;
        }
      }
    }
    &.open {
      ul {
        display: block;
        z-index: 2;
      }
    }
  }
  .custom-checkbox {
    display: flex;
    align-items: center;
    span{
      padding-left: 5%;
    }
    input[type="checkbox"] {
      border-radius: 2px;
      width: 14px;
      height: 14px;
      appearance: none;
      border: 1px solid #d9d9d9;
      background: #fff;
      position: relative;
      cursor: pointer;
      &:checked {
        &:after {
          color: #476da7;
          border: solid #476da7;
          border-width: 0 2px 2px 0;
          content: "";
          position: absolute;
          top: 0.1rem;
          right: 0.3rem;
          width: 0.5rem;
          height: 0.8rem;
          transform: rotate(45deg);
        }
      }
    }
    label {
      margin-left: 10px;
    }
  }
  .caret {
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 50%;
    right: 15px;
    margin-top: -3px;
    transform: rotate(45deg) translateY(-50%);
  }
  .note{
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }
</style>