<template>
<div>

  <div class="row">
    <div class="columns large-13 large-centered">
      <div class="content-block" v-if="step == 'check'" style="text-align:center">
        <svg width="200px"  height="200px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-eclipse" style="background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;">
          <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#1d3f72">
            <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
          </path>
        </svg>
      </div>
      <div class="content-block" v-if="step == 'logged_out'">
        <header>
          <div class="title">Logging you into your account</div>
        </header>
        <div class="content auth-content">
          {{$route.query}}
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
    export default {
        name: "login_as",
        data: function () {
            return {
                step: "check",
                activate_resend: null,
            }
        },
        created() {
            if (this.$route.query.token){
                this.activate_resend = this.$route.query.token
            }

            this.$http.post('/api/signin_as', this.$route.query).then((u) => {
                if (!u.data.object){
                    this.step='logged_out'
                    this.$store.commit('logout')
                }else{
                    this.$store.commit('redirectAfterLogin', u.data)
                }
            })
        },
        methods: {
            onLogin() {
                this.$store.commit('clearFlash')
                let that = this

                this.$validator.validateAll().then((result) => {
                    if (!result) return;

                    that.$http.post('/api/login', {
                        user: {email: that.email, password: that.password}
                    }).then(that.successCallback, that.errorCallback);
                })
            },
            successCallback(res) {
                let json = res.data

                if (json.completed) {
                    this.$store.commit('redirectAfterLogin', json)
                } else {
                    console.log(json.errors)

                    if (json.errors.deactivated){
                        this.$store.state.flash_alert =
                            "We're sorry. " +
                            "This account has been deactivated. " +
                            "Please contact ArbiterSports for more information."
                    }else if(json.errors.activate_resend){
                        this.activate_resend = json.errors.activate_resend[0]
                    }else{
                        this.$parent.modal = json.modal
                        this.$store.state.flash_alert =
                            "You have entered an invalid email/password combination. " +
                            "Please try again or click the 'Forgot password?' " +
                            "link below to reset your password."
                    }


                }
            },
            errorCallback() {
                alert("Unknown error happened")
            }
        }
    }
</script>
