<template>
  <div>
    <div class="row">
      <div class="columns large-13 large-centered">
        <div class="content-block">
          <header>
            <div class="title">{{t('app.reset_pass.title')}}</div>
          </header>
          <div class="content auth-content">
            <Form ref="form"
                  tag="form"
                  data-auth=""
                  id="new_user"
                  class="new_user"
                  @keydown.enter.prevent="onSubmit">
              <div class="row">
                <div class="form-col large-10">
                  <label for="password">
                    {{t('app.reset_pass.password')}}
                    <small>{{t('app.reset_pass.min')}}</small>
                  </label>
                </div>

                <div class="form-col large-14">
                  <div class="field">
                    <Field mode="lazy"
                           v-slot="{field, errors}"
                           rules="required"
                           name="password">
                      <input type="password"
                             v-bind="field"
                             id="password"
                             :placeholder="t('app.reset_pass.password')"
                             name="password"
                             v-model="password"
                             :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                      <span v-if="password_error" class="error">{{password_error}}</span>
                    </Field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-col large-10">
                  <label for="password_confirmation">
                    {{t('app.reset_pass.confirm_passoword')}}
                    <small>{{t('app.reset_pass.retype_password')}}</small>
                  </label>
                </div>

                <div class="form-col large-14">
                  <div class="field">
                    <Field
                        mode="lazy"
                        v-slot="{field, errors}"
                        rules="required|confirmed:@password"
                        :name="t('app.reset_pass.password_confirmation').toLowerCase()">
                      <input type="password"
                             id="password_confirmation"
                             v-bind="field"
                             :placeholder="t('app.reset_pass.retype_password2')"
                             name="password_confirmation"
                             v-model="password_confirmation"
                             :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                    </Field>
                  </div>
                </div>
              </div>

              <div class="row separator-row"></div>
              <div class="row button-row">
                <div class="form-col large-24 reverse-button-order">
                  <div class="buttons left small">
                    <a :href="$fid.weburl" class="button secondary small">{{t('app.general.back_home')}}</a>
                  </div>

                  <div class="buttons right small">
                    <router-link to="/login" class="button secondary small">{{t('app.general.cancel')}}</router-link>

                    <button class="button" @click.prevent="onSubmit">{{t('app.general.reset')}}</button>
                  </div>
                </div>
              </div>
            </Form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import helpers from 'code/base_mixins'

    export default {
        name: "reset-pass",
        mixins: [helpers],
        data() {
            return {
                password: "",
                password_confirmation: "",
                password_error: null
            }
        },
        created() {
            if ((this.$route.params.token || "").length == 0) this.$router.push({path: "/"})
        },
        beforeMount (){
            this.$store.commit('clearFlash')
        },
        methods: {
            onSubmit() {
                this.$store.commit('clearFlash')
                let that = this
                this.$refs.form.validate().then((result) => {
                    if (!result) return;

                    that.$http.post('/api/reset_pass', {
                        user: {password: that.password, password_confirmation: that.password_confirmation},
                        token: that.$route.params.token
                    }).then(that.successCallback, that.errorCallback);
                })
            },
            successCallback(res) {
                let json = res.data

                if (json.completed) {
                    this.$router.push({path: "/login"})
                } else {
                    if (json.errors.hasOwnProperty("token_not_found") || json.errors.base) {
                        this.$store.state.flash_alert =
                            I18n.t('app.reset_pass.expired1') +
                            "<a href='/forgot'>"+I18n.t('app.reset_pass.expired2')+"</a>\n" +
                                I18n.t('app.reset_pass.expired3')

                    } else if (json.errors.hasOwnProperty("password") || json.errors['psw.password']) {
                        this.password_error = I18n.t('app.signup.password_error')
                    }else{
                      this.$store.state.flash_alert = I18n.t('app.reset_pass.error')
                    }
                }

            },
            errorCallback() {
                alert("Unknown error happened")
            }
        }
    }
</script>
<style scoped lang="scss">
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }
</style>
