<template>
  <div>

    <div class="row">
      <div class="columns large-16 large-centered">
        <div class="content-block">
          <header>
            <div class="title">{{t('app.signup.title')}}</div>
          </header>
          <div class="content auth-content">
            <Form
                ref="form"
                tag="form"
                data-auth=""
                id="new_user"
                class="new_user"
                @keydown.enter.prevent="onSubmit">
                <div class="row" v-if="$root.web_msg" style="padding: 0rem 1rem 1rem;">
                    <div class="form-col large-24">
                        {{$root.web_msg}}
                    </div>
                </div>
              <div class="row">
                <div class="form-col large-8">
                  <label for="user_first_name">
                    {{t('app.signup.first_and_last_name')}}
                    <span class="required">*</span>
                    <small>{{t('app.signup.both_required')}}</small>
                  </label>
                </div>

                <div class="form-col large-8">
                  <div class="field">
                    <Field
                        rules="required"
                        v-model="user.first_name"
                        v-slot="{field, errors}"
                        :name="t('app.signup.first_name_placeholder').toLowerCase()">
                      <input type="text"
                             id="user_first_name"
                             v-bind="field"
                             :placeholder="t('app.signup.first_name_placeholder')"
                             :name="t('app.signup.first_name_placeholder').toLowerCase()"
                             :class="errors[0] && 'error'"/>
                    </Field>
                  </div>
                </div>

                <div class="form-col large-8">
                  <div class="field">
                    <Field
                        rules="required"
                        v-model="user.last_name"
                        v-slot="{field, errors}"
                        :name="t('app.signup.last_name_placeholder').toLowerCase()">
                      <input
                          type="text"
                          id="user_last_name"
                          v-bind="field"
                          :placeholder="t('app.signup.last_name_placeholder')"
                          :name="t('app.signup.last_name_placeholder').toLowerCase()"
                          :class="errors[0] && 'error'"/>
                    </Field>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-col large-8">
                  <label for="email">
                    {{t('app.signup.email')}}
                    <span class="required">*</span>
                    <small>{{t('app.signup.valid_email')}}</small>
                  </label>
                </div>

                <div class="form-col large-16">
                  <div class="field">
                    <Field
                        mode="lazy"
                        rules="required|email"
                        v-slot="{field, errors}"
                        v-model="user.email"
                        name="email">
                      <input
                          type="text"
                          id="email"
                          v-bind="field"
                          :placeholder="t('app.signup.email_placeholder')"
                          :name="t('app.signup.email').toLowerCase()"
                          :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                    </Field>
                  </div>
                </div>

                <div class="form-col large-16">
                  <div class="field">
                    <Field
                        mode="lazy"
                        v-model="user.email_confirmation"
                        v-slot="{field, errors}"
                        rules="required|email|confirmed:@email"
                        name="email confirmation">
                      <input type="email"
                             id="user_email_confirmation"
                             v-bind="field"
                             :placeholder="t('app.signup.retype_email_placeholder')"
                             :name="t('app.signup.email_confirmation').toLowerCase()"
                             :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                    </Field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-col large-8">
                  <label for="user_password">
                    {{t('app.signup.password')}} <span class="required">*</span>
                    <small>{{t('app.signup.min')}}</small>
                  </label>
                </div>

                <div class="form-col large-8">
                  <div class="field">
                    <Field
                        mode="lazy"
                        rules="required"
                        v-slot="{field, errors}"
                        v-model="user.password"
                        name="password">
                      <input type="password"
                             id="user_password"
                             v-bind="field"
                             autocomplete="off"
                             :placeholder="t('app.signup.password')"
                             :name="t('app.signup.password').toLowerCase()"
                             :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                      <span v-if="password_error" class="error">{{password_error}}</span>
                    </Field>
                  </div>
                </div>

                <div class="form-col large-8">
                  <div class="field">
                    <Field
                        mode="lazy"
                        rules="required|confirmed:@password"
                        v-model="user.password_confirmation"
                        v-slot="{field, errors}"
                        name="password confirmation">
                      <input type="password"
                           id="user_password_confirmation"
                           v-bind="field"
                           autocomplete="off"
                           :placeholder="t('app.signup.retype_password')"
                           :name="t('app.signup.password_confirmation').toLowerCase()"
                           :class="errors[0] && 'error'">
                      <span v-if="errors[0]" class="error">{{errors[0]}}</span>
                    </Field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-col large-8">
                  <label for="user_mobile_phone">
                    {{t('app.signup.mobile_number')}}<small>{{t('app.signup.in_case')}}</small>
                  </label>
                </div>

                <div class="form-col large-8">
                  <div class="field.int-tel-input">
                    <phone
                            :country-abbr="user.countryAbbr"
                            @country-change="countryCodeChange"
                            @phone-change="phoneNumberChange"
                            @valid-change="phoneNumberValid"
                    ></phone>
                    <span class="error" v-if="user.mobile_phone && phone_invalid">{{phone_invalid}}</span>
                  </div>
                  <div class="label-text" v-html="t('app.signup.sign_up_sms_terms')">
                      </div>
                </div>
              </div>

              <div class="row separator-row"></div>
              <div class="row top">
                <div class="form-col large-16 push-8">
                  <div class="field checkbox-field">
                    <label for="user_send_marketing_emails">
                      <div class="checkbox-container">
                        <input type="checkbox"
                               id="user_send_marketing_emails"
                               class="hidden-field"
                               name="send_marketing_emails"
                               v-model="user.send_marketing_emails">
                        <span class="checkmark" :class="{'checked': user.send_marketing_emails}"></span>
                      </div>
                      <div class="label-text">{{t('app.signup.join')}}
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row top">
                <div class="form-col large-16 push-8">
                  <div class="field checkbox-field">
                    <Field
                        rules="required"
                        name="agree_with_terms"
                        v-slot="{field, errors}">
                      <label for="user_agree_with_terms">
                        <div class="checkbox-container">
                          <input type="checkbox"
                                 v-bind="field"
                                 id="user_agree_with_terms"
                                 name="agree_with_terms"
                                 v-model="user.agree_with_terms"
                                 :class="errors[0] && 'error'">
                          <span class="checkmark" :class="{'checked': user.agree_with_terms}"></span>
                        </div>
                        <div class="label-text" :class="errors[0] && 'error'">
                          {{t('app.signup.i_agree')}}
                          <span class="required">*</span>
                          <br>
                          <div class="terms-link">
                            <a target="_blank" href="https://hello.familyid.com/terms-of-service">{{t('app.signup.read_terms')}}</a>
                          </div>
                        </div>
                      </label>
                    </Field>
                  </div>
                </div>
              </div>

              <div class="row top">
                <div class="form-col large-16 push-8">
                  <div class="field checkbox-field">
                    <label for="org_account">
                      <div class="checkbox-container">
                        <input type="checkbox"
                               id="org_account"
                               class="hidden-field"
                               name="org_account"
                               v-model="org_account">
                        <span class="checkmark" :class="{'checked': org_account}"></span>
                      </div>
                      <div class="label-text">{{t('app.signup.for_orgs_only')}}
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div id="organization-fields" v-if="org_account">
                <header>
                  <div class="title">{{t('app.signup.new_org_request')}}</div>
                </header>
                <h3>
                  {{ t('app.signup.org_desc_text') }}
                </h3>
                <div class="row">
                  <div class="form-col large-8">
                    <label for="user_organization_name">
                      <span>{{t('app.signup.org_name')}} <span class="required">*</span></span>
                      <small>{{t('app.signup.org_name_ex')}}</small>
                    </label>
                  </div>
                  <div class="form-col large-16">
                    <div class="field">
                      <Field
                          rules="required"
                          v-model="user.organization.name"
                          v-slot="{field, errors}"
                          :name="t('app.signup.org_name_placeholder').toLowerCase()">
                        <input type="text"
                               id="user_organization_name"
                               v-bind="field"
                               :name="t('app.signup.org_name_placeholder').toLowerCase()"
                               :placeholder="t('app.signup.org_name_placeholder')"
                               :class="errors[0] && 'error'">
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-col large-8">
                    <label>
                      <span>{{t('app.signup.org_type')}}</span>
                      <span class="required">*</span>
                    </label>
                  </div>
                  <div class="form-col large-16">
                    <div class="field">
                      <Field
                          v-model="user.organization.org_type_helper"
                          rules="required"
                          v-slot="{field, errors}"
                          name="org_type">
                        <div class="select has-id-user_organization_org_type" :class="{error: errors[0]}">
                          <span class="selected open">
                            <span class="value">{{GetOrgTypes()[user.organization.org_type_helper] || t('app.signup.select_org_type')}}</span>
                            <span class="caret"></span>
                          </span>

                          <select id="user_organization_org_type"
                                  v-bind="field"
                                  name="org_type"
                                  @change="user.organization.org_type= user.organization.org_type_helper"
                                  :class="errors[0] && 'error'">
                            <option :value="value" v-for="(label, value) of GetOrgTypes()">{{label}}</option>
                          </select>

                        </div>
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-col large-8">
                    <label>
                      <span>{{t('app.signup.org_state')}} <span class="required">*</span></span>
                    </label>
                  </div>
                  <div class="form-col large-16">
                    <div class="field">
                      <Field
                          v-model="user.organization.state"
                          rules="required"
                          v-slot="{field, errors}"
                          :name="t('app.signup.state').toLowerCase()">
                        <div class="select has-id-user_organization_addresses_attributes_0_state placeholder" :class="{error: errors[0]}">
                          <span class="selected open">
                            <span class="value">
                              {{user.organization.state|| t('app.signup.state')}}
                            </span>
                            <span class="caret"></span>
                          </span>

                          <select id="user_organization_addresses_attributes_0_state"
                                  v-bind="field"
                                  name="state"
                                  :class="errors[0] && 'error'">
                            <option :value="state" v-for="state in states">{{state}}</option>
                          </select>

                        </div>
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-col large-8">
                    <label for="user_organization_name">
                      <span>{{t('app.signup.org_title')}} <span class="required">*</span></span>
                    </label>
                  </div>
                  <div class="form-col large-16">
                    <div class="field">
                      <Field
                          rules="required"
                          v-model="user.organization.title"
                          v-slot="{field, errors}"
                          :name="t('app.signup.org_title').toLowerCase()">
                        <input type="text"
                               id="user_organization_title"
                               v-bind="field"
                               :name="t('app.signup.org_title').toLowerCase()"
                               :placeholder="t('app.signup.org_title')"
                               :class="errors[0] && 'error'">
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="user.organization.org_type_helper == 'Other (Please indicate type)'">
                  <div class="form-col large-16 large-offset-8">
                    <div class="field">
                      <span class="float-placeholder">{{t('app.signup.enter_other')}}</span>
                      <Field
                          rules="required"
                          name="org_type_other"
                          v-model="user.organization.org_type_other"
                          v-slot="{field, errors}">
                        <input name="org_type_other"
                               v-bind="field"
                               @change="user.organization.org_type= user.organization.org_type_other"
                               type="text"
                               :placeholder="t('app.signup.enter_other')">
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row bottom">
                  <div class="form-col large-8">
                    <label for="user_organization_phone">
                      <span>{{t('app.signup.org_phone')}} <span class="required">*</span></span>
                    </label>
                  </div>
                  <div class="form-col large-16">
                    <div class="field int-tel-input" :class="{error: org_phone_invalid}">
                      <phone
                              :country-abbr="user.organization.countryAbbr"
                              @country-change="countryCodeOrgChange"
                              @phone-change="phoneNumberOrgChange"
                              @valid-change="phoneNumberOrgValid"
                              data-tel="organization"
                      ></phone>
                      <span class="error" v-if="user.organization.phone && org_phone_invalid"> {{org_phone_invalid}}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-col large-8">
                    <label for="user_organization_org_email">
                      <span>{{t('app.signup.org_email')}} <span class="required">*</span></span>
                    </label>
                  </div>
                  <div class="form-col large-16">
                    <div class="field">
                      <Field
                          rules="required|email"
                          v-model="user.organization.email"
                          v-slot="{field, errors}"
                          :name="t('app.signup.org_email').toLowerCase()">
                        <input type="text"
                               id="user_organization_org_email"
                               v-bind="field"
                               name="email"
                               :placeholder="t('app.signup.org_email')"
                               :class="errors[0] && 'error'">
                        <span class="error" v-if="errors[0]">{{errors[0]}}</span>
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row top">
                  <div class="form-col large-16 push-8">
                    <div class="field checkbox-field">
                      <Field
                          rules="required"
                          name="user_organization_agreement"
                          v-slot="{field, errors}">
                        <label for="user_organization_agreement">
                          <div class="checkbox-container">
                            <input type="checkbox"
                                   v-bind="field"
                                   id="user_organization_agreement"
                                   name="agree_with_terms"
                                   v-model="user.organization.agreement"
                                   :class="errors[0] && 'error'">
                            <span class="checkmark" :class="{'checked': user.organization.agreement}"></span>
                          </div>
                          <div class="label-text" :class="errors[0] && 'error'">
                            {{t('app.signup.i_agree_organization')}}
                            <span class="required">*</span>
                          </div>
                        </label>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row separator-row"></div>
              <div class="row button-row">
                <div class="form-col large-24 reverse-button-order">
                  <div class="buttons right small">
                    <button class="button" :disabled="button_disabled" @click.prevent="onSubmit">
                      <span v-if="button_disabled">Sending...</span>
                      <span v-else>{{t('app.general.submit')}}</span>
                    </button>

                  </div>
                  <div class="buttons left small">
                    <router-link
                      v-if="$fid.homeurl"
                      class="button secondary small"
                      :to="$fid.homeurl">{{t('app.general.back_home')}}</router-link>
                    <a v-if="!$fid.homeurl" :href="$fid.weburl" class="button secondary small">{{t('app.general.back_home')}}</a>
                  </div>

                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
    import { VueReCaptcha } from 'vue-recaptcha-v3'
    import Phone from './phone'
    import helpers from 'code/base_mixins'
    import { defineRule } from 'vee-validate'

    defineRule("url_regex", (value) => {
      const web_site_pattern = /(https?:\/\/(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?:\/\/(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})/

      if (!value || !value.length) {
        return true;
      }
      if (!web_site_pattern.test(value)) {
        return false;
      }
      return true;
    });

    const states = ["AK", "AL", "AR","AZ","CA","CO","CT","DC","DE","FL","GA","HI", "IA","ID","IL","IN","KS","KY","LA","MA","MD","ME","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VA","VT","WA","WI","WV","WY"]
    export default {
        name: "signup",
        mixins: [helpers],
        components: {
          Phone
        },
        data() {
            return {
                phone_invalid: null, org_phone_invalid: null,
                invite_token: null,
                org_confirm_modal: false,
                button_disabled: false,
                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    email_confirmation: '',
                    password: '',
                    password_confirmation: '',
                    mobile_phone: '',
                    send_marketing_emails: true,
                    agree_with_terms: false,
                    organization: {
                        name: '',
                        phone: null,
                        org_type: null,
                        org_type_helper: null,
                        org_type_other: null,
                        state: null,
                        email: null,
                        title: null,
                        agreement: false
                    },
                    countryAbbr: 'us',
                    countryCode: ''
                },
                org_account: false,
                password_error: null,
                email_error: null,
                email_translate_error: I18n.t('app.signup.email_exist'),
                states,
            }
        },

        created (){
            this.mergePrefillData()
            if (window.need_captcha){
              this.loadCaptcha()
            }else{
              this.button_disabled = false
            }
        },
        watch: {
            '$root.prefill' (val){
                this.mergePrefillData()
            },
        },
        mounted (){
            this.org_phone_invalid = null
        },
        beforeMount (){
            this.$store.commit('clearFlash')
        },
        methods: {
            mergePrefillData (){
                if (this.$root.prefill){
                    for (let variable of Object.keys(this.$root.prefill)) {
                        let obj = this.$root.prefill[variable]
                        if (!obj) continue;
                        if (typeof obj == 'string'){this[variable] = obj;continue}
                        for (let attr of Object.keys(obj)){
                            this[variable][attr] = obj[attr]
                        }
                    }
                }
            },
            GetOrgTypes () {
              return {
                "Schools": I18n.t('app.signup.org_types.schools'),
                "Childcare": I18n.t('app.signup.org_types.childcare'),
                "Religious institutions": I18n.t('app.signup.org_types.religious'),
                "Camps": I18n.t('app.signup.org_types.camps'),
                "Sports": I18n.t('app.signup.org_types.sports'),
                "Arts": I18n.t('app.signup.org_types.arts'),
                "Classes/Activities": I18n.t('app.signup.org_types.classes'),
                "School Districts": I18n.t('app.signup.org_types.school_districts'),
                "Community Programs": I18n.t('app.signup.org_types.community_programs'),
                "Other (Please indicate type)": I18n.t('app.signup.org_types.others')
              }
            },
            countryCodeChange(country) {
                this.user.countryAbbr = country.addr
                this.user.countryCode = country.code
            },
            phoneNumberChange(phone, event) {
                this.user.mobile_phone = phone
            },
            phoneNumberValid(bool) {
                this.phone_invalid = !bool ? I18n.t('app.signup.phone_invalid') : null
            },
            countryCodeOrgChange(country) {
                this.user.organization.countryAbbr = country.addr
                this.user.organization.countryCode = country.code
            },
            phoneNumberOrgChange(phone, event) {
                this.user.organization.phone = phone
            },
            phoneNumberOrgValid(bool) {
                this.org_phone_invalid = !bool ? I18n.t('app.signup.phone_invalid') : null
            },

            async onSubmit() {
                let valid = true
                this.org_confirm_modal = false
                this.$store.commit('clearFlash')
                this.password_error = null
                //this.phone_invalid = null
                const result = this.$refs.form.validate()
                if (this.org_account) {
                    if (this.org_phone_invalid) return
                    if (!this.user.organization.phone) {
                        this.org_phone_invalid = true
                        return
                    }
                }
                if (document.querySelector('input[type="tel"]').value && this.phone_invalid) return;
                await result.then((res)=> {
                  valid = res.valid
                })
                if(!valid){return}
                this.button_disabled   = true
                this.$http.post('/api/signup', {
                    user: this.user,
                    org_account: this.org_account,
                    invite_token: this.invite_token,
                    captcha: await this.getCaptchaToken(),
                    js_need: window.need_captcha || 'false',
                }).then(this.successCallback, this.errorCallback);
            },
            successCallback(res) {
                let json = res.data

                if (json.completed && json.object) {
                    this.$store.commit('redirectAfterLogin', json)
                }else if(json.completed){
                    let query = {}
                    if (json.errors && json.errors.activate_resend){
                        query.activate = json.errors.activate_resend
                        query.email = this.user.email

                    }
                    this.$router.push({path: "/login", query: query})
                } else {
                    if (json.errors.email && json.errors.email === 'A Student Registration account with this email address already exists') {
                        this.$store.state.flash_alert =
                            I18n.t('app.signup.already_have_account1') +
                            "<a class='auth_alert_link' href='/login'>"+I18n.t('app.signup.already_have_account2')+"</a> " +
                                I18n.t('app.signup.already_have_account3')
                        this.$nextTick(()=> document.querySelector(".flash-message.alert").scrollIntoView() )
                    }else if(json.errors.email){
                        this.$store.state.flash_alert = json.errors.email.join(", ")
                        this.$nextTick(()=> document.querySelector(".flash-message.alert").scrollIntoView() )
                    }else if(json.errors.password || json.errors['psw.password']){
                      this.password_error = I18n.t('app.signup.password_error')
                    }else if(json.errors.mobile_phone){
                        this.phone_invalid = json.errors.mobile_phone.join(", ")
                        this.phone_invalid = this.phone_invalid.replace('has already been taken', I18n.t('app.signup.number_in_use'))
                    }else if(json.errors.captcha){
                      window.need_captcha = true
                      this.loadCaptcha().then(this.onSubmit)
                    }else{
                        this.$store.state.flash_alert = I18n.t('app.signup.please_review')
                    }
                }

                this.button_disabled = false
            },
            errorCallback() {
              this.button_disabled = false
              alert("Unknown error happened")
            },
            async getCaptchaToken(){
              if (window.need_captcha){
                await this.$recaptchaLoaded()
                return await this.$recaptcha('signup');
              }else{
                return ""
              }
            },
            loadCaptcha(){
              // Not a good way to apply VueReCaptcha.
              // But I cannot yet find better solution to access the variable app declared in application.js
              this.$app.use(VueReCaptcha, {siteKey: '6Lcf7NMZAAAAAKMkXn1U8zMLJ0dY5W05_HukwB1C'})
              return this.getCaptchaToken()
            }
        }
    }
</script>

<style scoped>
  .value{
    font-size: 14px
  }
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;

  }
  .select.error{
    line-height: initial !important;
    height: 10px !important;
  }
</style>
