import 'css/layout.scss'
import { createApp } from 'vue';
import App from 'templates/app.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)
import {store} from "code/store.js"
import {router} from "code/routes.js"

window.csrf = ()=>{
    return document.querySelector("meta[name='csrf-token']")?.content
}

axios.defaults.withCredentials = true
axios.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = I18n.locale
    config.headers['X-CSRF-Token'] = window.csrf()
    config.credentials = true
    return config;
});

import { Form, Field, ErrorMessage } from 'vee-validate';
app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)

import { defineRule } from 'vee-validate';
import { required, email, min ,confirmed } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('confirmed', confirmed);

app.config.globalProperties.$app = app
if (location.pathname.startsWith("//")){
    window.location = `${location.pathname.replace(/\/\//,"/")}${location.search}`
}
document.addEventListener('DOMContentLoaded', () => {
    const props = JSON.parse(document.body.getAttribute('data'))
    app.config.globalProperties.$fid = {}
    for (let k of Object.keys(props)) app.config.globalProperties.$fid[k] = props[k]

    app.use(store)
        .use(router)
        .use(VueAxios, axios)
        .mount('#main-app')
})
