<template>
    <div class="row">
        <div class="columns large-centered" :class="largeClass()">
            <div class="content-block alert" v-if="state.flash_alert">
                <div class="flash-message alert" style="margin-bottom:1.25rem;margin-top:0;" v-html="state.flash_alert"></div>
            </div>
            <div class="content-block success" v-if="state.flash_notice">
                <div class="flash-message success" style="margin-bottom:1.25rem;margin-top:0;" v-html="state.flash_notice"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "flash",
        computed: {
            state() {
                return this.$store.state
            }
        },
        methods: {
            largeClass(){
                switch (this.$parent.action){
                    case 'login':
                    case 'reset-pass':
                    case 'reset-email':
                    case 'forgot-email':
                        return 'large-13'
                    case 'forgot-pass':
                        return (this.$parent.$refs.view && this.$parent.$refs.view.form_sent) ? 'large-16' : 'large-13'
                    default:
                        return 'large-16'
                }
            }
        }
    }
</script>
