<template>
<div>
  <div class="row" v-if="activate_resend">
    <div class="columns large-13 large-centered">
      <div class="content-block alert">
        <header>
          <div class="flash-message success" style="text-transform: none">
            <span v-html="t('app.login.activation_sent_1', {email: $route.query.email || email})"/>
            <router-link class='auth_alert_link' :to="'/resend-activate/'+activate_resend"> {{t('app.login.activation_sent_2')}}</router-link>
          </div>
        </header>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="columns large-13 large-centered">
      <div class="content-block" v-if="step === 'check'" style="text-align:center">
        <svg width="200px"  height="200px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-eclipse" style="background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;">
          <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#1d3f72">
            <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
          </path>
        </svg>
      </div>
      <div class="content-block" v-if="step === 'logged_out'">
        <header>
          <div class="title">{{t('app.login.log_in_to_your_account')}}</div>
        </header>
        <div class="content auth-content">
          <Form data-auth=""
                id="new_user"
                ref="form"
                tag="form"
                class="new_user"
                @keydown.enter.prevent="onLogin">
            <div class="row" v-if="$root.web_msg" style="padding: 0 1rem;">
              <div class="form-col large-24">
                {{$root.web_msg}}
              </div>
            </div>
            <div class="row">
              <div class="form-col large-10">
                <label for="user_email">

                  {{t('app.login.email')}}
                  <span class="required">*</span>
                  <small>{{t('app.login.type_email')}}</small>
                </label>
              </div>

              <div class="form-col large-14">
                <div class="field">
                  <Field
                      rules="email"
                      mode="lazy"
                      v-slot="{field, errors}"
                      :name="t('app.login.email').toLowerCase()">
                    <input
                        type="text"
                        id="user_email"
                        v-bind="field"
                        :placeholder="t('app.login.email')"
                        tabindex="1"
                        :name="t('app.login.email').toLowerCase()"
                        v-model="email"
                        :class="errors[0] && 'error'"
                    />
                  </Field>
                  <div class="forgot">
                    <router-link to="/forgot-email" tabindex="2">{{t('app.login.reset_email')}}</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-col large-10">
                <label for="user_password">
                  {{t('app.login.password')}}
                  <span class="required">*</span>
                  <small>{{t('app.login.type_password')}}</small>
                </label>
              </div>

              <div class="form-col large-14">
                <div class="field">
                  <Field
                      rules="required|min:8"
                      v-slot="{field, errors}"
                      :name="t('app.login.password').toLowerCase()">
                    <input
                        type="password"
                        id="user_password"
                        autocomplete="off"
                        v-bind="field"
                        tabindex="1"
                        :name="t('app.login.password').toLowerCase()"
                        v-model="password"
                        :placeholder="t('app.login.password_placeholder')"
                        :class="errors[0] && 'error'"
                    />
                  </Field>
                  <div class="forgot">
                    <router-link to="/forgot" tabindex="2">{{t('app.login.forgot_password')}}</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row separator-row"></div>
            <div class="row button-row">
              <div class="form-col large-24 reverse-button-order">
                <div class="buttons right small">
                  <button class="button" @click.prevent="onLogin">{{t('app.general.login')}}</button>
                </div>

                <div class="buttons left small">
                  <router-link
                    v-if="$fid.homeurl"
                    class="button secondary small"
                    :to="$fid.homeurl">{{t('app.general.back_home')}}</router-link>
                  <a v-if="!$fid.homeurl" :href="$fid.weburl" class="button secondary small">{{t('app.general.back_home')}}</a>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
    import helpers from 'code/base_mixins'

    export default {
        name: "login",
        mixins: [helpers],

        data: function () {
            return {
                step: "check",
                activate_resend: null,
                email: "",
                password: "",
            }
        },
        created() {
            // let user
            // if (user = window.localStorage.getItem('fid-account-current-user')) {
            //
            //     this.$store.state.current_user = JSON.parse(user)
            // }
            if (this.$route.query.activate){
                this.activate_resend = this.$route.query.activate
            }

            this.$http.get('/api/user').then((u) => {
                if (!u.data.object){
                    this.step='logged_out'
                    this.$store.commit('logout')
                }else{
                    this.$store.commit('redirectAfterLogin', u.data)
                }
            })
        },
        beforeMount (){
            this.$store.commit('clearFlash')
        },
        methods: {
            onLogin() {
                this.$store.commit('clearFlash')
                let that = this
                this.$refs.form.validate().then((result) => {
                    if (!result) return;

                    that.$http.post('/api/login', {
                        user: {email: that.email, password: that.password}
                    }).then(that.successCallback, that.errorCallback);
                })
            },
            successCallback(res) {
                let json = res.data

                if (json.completed) {
                    this.$store.commit('redirectAfterLogin', json)
                } else {

                    if (json.errors.deactivated){
                        this.$store.state.flash_alert = I18n.t('app.login.account_was_deactivated')
                    }else if(json.errors.activate_resend){
                        this.activate_resend = json.errors.activate_resend[0]
                    }else{
                        this.$root.modal = json.modal
                        this.$store.state.flash_alert = I18n.t('app.login.invalid_email_pass')

                    }


                }
                // if (json.object){
                //     this.$store.state.current_user = json.object
                //     if (this.remember){
                //         window.localStorage.setItem('fid-account-current-user', JSON.stringify(this.$store.state.current_user))
                //     }
                // }else{
                //     let err
                //     if (err = json.errors.hasOwnProperty('login_failure')){
                //         console.log(json.errors)
                //     }else{
                //         console.log(res)
                //     }
                // }
            },
            errorCallback() {
                alert("Unknown error happened")
            }
        }
    }
</script>
<style scoped lang="scss">
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }
</style>
