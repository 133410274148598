<template>
  <div class="columns large-13 large-centered" style="width:523.417px;font-size: 0.875rem;">
    <div class="content-block">
      <header>
        <div class="title">{{t('app.login-signup.title')}}</div>
      </header>
      <div class="content">
        <p>
          {{$parent.web_msg}}
        </p>
        <br>
        <p>
          {{t('app.login-signup.if_you_didnt_use')}}
        </p>
        <ul>
          <li>
            {{t('app.login-signup.create_account')}}
          </li>
        </ul>
        <br>
        <p>
          {{t('app.login-signup.already_have_account')}}
        </p>
        <ul>
          <li>
            {{t('app.login-signup.login_to_account')}}
          </li>
        </ul>
        <br>
        
        <p>
          {{t('app.login-signup.using')}}
        </p>
        <div class="row separator-row"></div>
        <div class="row buttons-row">
          <div class="form-col large-24 reverse-button-order">
            <div class="buttons left small">
              <router-link 
                class="button secondary small"
                to="/signup">
                {{t('app.general.create_account')}}
              </router-link>
            </div>
            
            <div class="buttons right small">
              <router-link
                class="button"
                to="/login">
                {{t('app.general.login')}}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
    import helpers from 'code/base_mixins'

    export default {
    name: 'login-signup',
    mixins: [helpers],
    created(){
      this.$fid.homeurl="/login-signup"
      this.$fid.skipOrgCreation = true
    }
  }
  </script>
  <style scoped>
  li{
    list-style:disc;
    margin-left: 25px;
  }
  </style>