import { createRouter, createWebHistory } from 'vue-router'

import Apps from '../templates/apps.vue'
import Login from '../templates/login.vue'
import LoginAs from '../templates/login_as.vue'
import SignUp from '../templates/signup.vue'
import LoginSignUp from '../templates/login-signup.vue'
import ForgotPass from '../templates/forgot_pass.vue'
import ResetPass from '../templates/reset_pass.vue'
import ForgotEmail from '../templates/forgot_email.vue'
import ResetEmail from '../templates/reset_email.vue'

import ResendActivate from '../templates/resend_activate.vue'
import Activate from '../templates/activate.vue'

import OauthMain from '../templates/oauth/main.vue'

const routes = [
    {path: '/',       component: Login, meta:{title: 'login'}},
    {path: '/login',  component: Login, meta:{title: 'login'}},
    {path: '/signup', component: SignUp, meta:{title: 'create_account'}},
    {path: '/login-signup', component: LoginSignUp, props:{no_login_footer: true}},
    {path: '/login-as',     component: LoginAs},
    {path: '/signin_as',    component: LoginAs},
    {path: '/apps',   component: Apps},
    {path: '/forgot', component: ForgotPass, meta:{title: 'forgot_pass'}},
    {path: '/reset',  component: ResetPass},
    {path: '/reset/:token',  component: ResetPass},
    {path: '/forgot-email', component: ForgotEmail, meta:{title: 'reset_email'}},
    {path: '/reset-email',  component: ResetEmail},
    {path: '/reset-email/:token',  component: ResetEmail},

    {path: '/resend-activate/:token', component: ResendActivate},
    {path: '/activate/:token',        component: Activate},
    {path: '/oauth/authorize',        component: OauthMain},
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})