<template>
  <component
      class="container clearfix"
      :is="getComponentName()"
      @delUser="delUser"
      @setUser="setUser"
      :userEmail="userEmail"
      :userName="userName"
  />
</template>

<script>
import login          from "./login.vue"
import check_password from "./check_password.vue"
import programs       from "./programs.vue"
export default {
  name: "oauth-main",
  components: {login,check_password,programs},
  data(){
    return {
      current_user: null,
      first_page: true,
      token: null,
      userNameCache: null,
    }
  },
  computed: {
    userEmail(){ return localStorage.getItem('pageUserEmail') },
    userName: {
      get(){ return (this.userNameCache ||= localStorage.getItem('pageUserName')) },
      set(val){
        this.userNameCache = val
        localStorage.setItem('pageUserName',val)
      }
    },
  },

  methods: {
    getComponentName(){
      let res
      if(this.first_page){
        if(this.userName && this.userName.length > 0){
          res="check_password"
        }else{
          res="login"
        }
      }else{
        res="programs"
      }
      return res
    },

    loadCurrentUser(){this.current_user = {}},
    logout()         {this.current_user = null},

    delUser(){
      localStorage.setItem('pageUserEmail','')
      this.userName     = ""
      this.current_user = {}
      this.first_page   = false // hack
      this.first_page   = true
      this.token        = null
    },

    setUser(res){
      localStorage.setItem('pageUserEmail', res.object.email)
      this.userName     = res.object.first_name + " " + res.object.last_name
      this.current_user = res.object
      this.first_page   = false
      this.token        = res.token
    },
  }
}
</script>