<template>
    <transition name="modal" v-if="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
            <div class="modal-container reveal-modal open" id="confirm-button-modal">
                <header data-draggable-modal="" class="draggable">
                    <div class="modal-title">{{modal.title|| t('app.modal.alert')}}</div>
                </header>
                <div class="content" style="border-radius: 0 0 4px 4px;">
                    <div class="row" v-if="modal.text">
                        <small data-text="">{{modal.text}}</small>
                    </div>
                </div>
                <div class="row button-row">
                    <div class="form-col large-24 reverse-button-order">
                        <div class="buttons left small">
                            <a class="button secondary small" @click.prevent="noClick" :class="modal.no_color">{{modal.no_button|| t('app.modal.no_button')}}</a>
                        </div>
                        <div class="buttons right small">
                            <a class="button"  @click.prevent="yesClick" :class="modal.yes_color">{{modal.yes_button|| t('app.modal.yes_button')}}</a>
                        </div>
                    </div>
                </div>
                <a class="close-reveal-modal" @click.prevent="close">×</a>
            </div>
            </div>
        </div>
    </transition>

</template>

<script>
    import helpers from 'code/base_mixins'

    export default {
        name: "modal",
        mixins: [helpers],
        props: ['modal'],
        data (){
            return {}
        },
        methods: {
            noClick (){
                if (this.modal.no_href){this.$router.push({path: this.modal.no_href})}
                if (this.modal.no_action){ this.modal.no_action() }
                this.$emit('close')
            },
            yesClick (){
                if (this.modal.yes_href){ this.$router.push({path: this.modal.yes_href})}
                if (this.modal.yes_action){ this.modal.yes_action() }
                this.$emit('close')
            },
            close (){
                this.$emit('close')
            }
        }
    }
</script>
