import {createStore} from 'vuex';

export const store = createStore({
    state: { // keyword to set variables
        current_user: null,
        current_org: null,
        flash: {},
        flash_alert: null,
        flash_notice: null
    },
    getters: {
        // campaign: (state) => (that) => { // state comes automatically
        //     let id = that.$route.query.campaign_id
        //     // not caching for now
        //     // if (state.cached_campaigns[id]) return Promise.resolve(state.cached_campaigns[id])
        //
        //     return that.$http.get(`/api/invite_campaigns/${id}`, {params: {organization_id: that.$route.params.id}}).then((res)=> {
        //         state.cached_campaigns[id] = res.body.object
        //         return state.cached_campaigns[id]
        //     })
        // }
    },
    mutations: {
        redirectAfterLogin (state, json){
            state.current_user = json.object
            window.localStorage.setItem('fid-account-current-user', JSON.stringify(state.current_user))
            window.location = json.redirect
        },
        logout (state) {
            // mutate state
            state.current_user = null
            window.localStorage.removeItem('fid-account-current-user')
        },
        calculated_logout (state, res) {
            // mutate state
            if (res.data.errors && res.data.errors.base && res.data.errors.base.includes("no_access_to_object")){
                console.log("session expired")
                res.that.$message.error('you need to re-login in Arbiter Registration', 5000)
                // this.logout(state)
                this._mutations.logout[0]()
            }
            if (res.data.object.errors && res.data.object.errors.base && res.data.object.errors.base.includes("no_access_to_object")){
                console.log("session expired")
                res.that.$message.error('you need to re-login in Arbiter Registration', 5000)
                // this.logout(state)
                this._mutations.logout[0]()
            }

        },
        setOrg (state, org){
            state.current_org = org
        },
        clearFlash (state){
            state.flash_alert  = null
            state.flash_notice = null
        }
    }
})
