<template>
  <div class="row">
    <div class="columns large-13 large-centered">
      <div class="content-block">
        <header>
          <div class="title">{{t('app.forgot_email.title')}}</div>
        </header>
        <div class="content auth-content">

          <form data-auth="" id="new_user" class="new_user" @keydown.enter.prevent="onSubmit">
            <div class="row">
              <div class="form-col large-8">
                <label for="user_phone">
                  {{t('app.forgot_email.enter_number')}}<span class="required">*</span>
                </label>
              </div>

              <div class="form-col large-16">
                <div class="field.int-tel-input">
                  <phone
                          :country-abbr="user.countryAbbr"
                          @country-change="countryCodeChange"
                          @phone-change="phoneNumberChange"
                          @valid-change="phoneNumberValid"
                  ></phone>
                  <span class="error" v-if="user.mobile_phone && !phone_valid"> {{t('app.forgot_email.invalid')}}</span>
                </div>
              </div>
            </div>

            <div class="row separator-row"></div>
            <div class="row button-row">
              <div class="form-col large-24 reverse-button-order">
                <div class="buttons left small">
                  <a :href="$fid.weburl" class="button secondary small">{{t('app.general.back_home')}}</a>
                </div>

                <div class="buttons right small">
                  <router-link to="/login" class="button secondary small">{{t('app.general.cancel')}}</router-link>

                  <button class="button" @click.prevent="onSubmit">{{t('app.general.reset')}}</button>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
    import Phone from './phone'
    import helpers from 'code/base_mixins'


    export default {
        name: "forgot-email",
        components: {Phone},
        mixins: [helpers],
        data() {
            return {
                mobile_phone: '', phone_valid: true, user: {
                    mobile_phone: '',
                    countryAbbr: 'us',
                    countryCode: ''
                }
            }
        },
        beforeMount (){
            this.$store.commit('clearFlash')
        },
        methods: {
            countryCodeChange(country) {
                this.user.countryAbbr = country.addr
                this.user.countryCode = country.code
            },
            phoneNumberChange(phone, event) {
                this.user.mobile_phone = phone
                window.sessionStorage.setItem("mobile_phone", phone)
            },
            phoneNumberValid(bool) {
                this.phone_valid = bool
            },

            onSubmit() {
                this.$store.commit('clearFlash')
                let that = this

                // this.$validator.validateAll().then((result) => {
                //     if (!result) return;
                if (!this.phone_valid) return;

                that.$http.post('/api/forgot_email', {
                    user: {mobile_phone: that.user.mobile_phone}
                }).then(that.successCallback, that.errorCallback);
                // })
            },
            successCallback(res) {
              let json = res.data

                if (json.completed) {
                    this.$store.state.flash_notice = I18n.t('app.forgot_email.text_message_sent')
                    this.$router.push({path: "/reset-email", query: {notice: "code_sent"}})
                } else {
                    this.$store.state.flash_alert = I18n.t('app.forgot_email.no_account')
                    if (json.errors) this.$store.state.flash_alert = json.errors[0]

                }

            },
            errorCallback() {
                alert("Unknown error happened")
            }
        }
    }
</script>
